import type { ReactNode } from "react";
import { Stack, FormControl, Typography, FormHelperText } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

export interface IInputLayoutProps {
  id?: string;
  label?: string | null;
  errorMessage?: string;
  helperMessage?: string;
  helper?: ReactNode;
  labelAdornment?: ReactNode;
  children: ReactNode;
  labelAdornmentFullWidth?: boolean;
  hasGroupError?: boolean;
  fullWidth?: boolean;
  visuallyHiddenLabel?: boolean;
}

export function InputLayout({
  label,
  errorMessage,
  helperMessage,
  id,
  helper,
  children,
  labelAdornment,
  labelAdornmentFullWidth = true,
  hasGroupError = false,
  fullWidth = true,
  visuallyHiddenLabel = false,
}: IInputLayoutProps): JSX.Element {
  const hasError = errorMessage !== undefined;
  let message = helperMessage;

  if (errorMessage) {
    if (Array.isArray(errorMessage)) {
      message = errorMessage.join("; ");
    } else {
      message = errorMessage;
    }
  }

  return (
    <FormControl
      fullWidth={fullWidth}
      error={Boolean(hasError || hasGroupError)}
    >
      <Stack
        spacing={0.5}
        direction="column"
        sx={{ mt: visuallyHiddenLabel && !labelAdornment ? -1 : 0 }}
      >
        {(label || labelAdornment) && (
          <Stack
            direction="row"
            justifyContent={
              labelAdornmentFullWidth ? "space-between" : "flex-start"
            }
            alignItems="center"
          >
            {label && (
              <Typography
                style={visuallyHiddenLabel ? visuallyHidden : undefined}
                data-testid="label"
                component="label"
                htmlFor={id}
                id={`${id}-label`}
                sx={{
                  fontSize: "0.75rem",
                  color: "text.secondary",
                  marginRight: 1,
                }}
              >
                {label}
              </Typography>
            )}
            {labelAdornment && (
              <Typography
                data-testid="labelAdornment"
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  flexGrow: labelAdornmentFullWidth ? 0 : 1,
                }}
              >
                {labelAdornment}
              </Typography>
            )}
          </Stack>
        )}
        {children}
        <div
          data-testid="helperText"
          id={`${id}_description`}
          // using style to increase specificity and overide Stack margin as needed
          style={{ marginTop: helper || message ? undefined : 0 }}
        >
          {helper || <FormHelperText>{message}</FormHelperText>}
        </div>
      </Stack>
    </FormControl>
  );
}
