import { ChangeEvent, ReactNode, useMemo, useState } from "react";
import { getIn, useField, useFormikContext } from "formik";
import { useTranslations } from "next-intl";
import { CircularProgress, InputAdornment } from "@mui/material";

import { BasicInput, IBasicInputProps } from "src/components/Input/BasicInput";
import { useFieldSetError } from "src/contexts/FieldSetError";
import { REQUIRED_MESSAGE_LOCALIZATION_KEY } from "src/components/Form/validation/const";

export type TFormFieldProps<P, V, E> = {
  idBase?: string;
  name: string;
  label: string;
  watchFieldNames?: string[];
  watchProps?: string[];
  component?: (props: P) => JSX.Element;
  disabled?: boolean;
  // TODO: correctly type these using dynamic values
  value?: V;
  onChange?: (value: V, event: ChangeEvent<E>) => void;
  required?: boolean;
  validation?: (
    value: V
  ) => string | undefined | null | Promise<string | undefined | null>;
  alwaysShowError?: boolean;
  endAdornment?: ReactNode; // 9807384876
} & Omit<P, "id" | "onChange" | "value">;

export function FormikField<
  P = IBasicInputProps,
  V = string,
  E = HTMLInputElement
>({
  component,
  name,
  watchFieldNames = [],
  watchProps = [],
  idBase = "input",
  validation,
  required,
  alwaysShowError,
  endAdornment,
  ...inputProps
}: TFormFieldProps<P, V, E>): JSX.Element {
  const t = useTranslations();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: any = component || BasicInput;

  const [isValidating, setIsValidating] = useState(false);

  const handleValidation = async (value: V): Promise<string | undefined> => {
    if (required && !value) {
      return t(REQUIRED_MESSAGE_LOCALIZATION_KEY);
    }

    if (validation) {
      setIsValidating(true);
      const validate = await validation(value);
      setIsValidating(false);

      return validate || undefined;
    }

    return undefined;
  };

  // only id is needed here, and not destructuring is beneficial
  // eslint-disable-next-line react/destructuring-assignment
  const [formikField, formikFieldMeta, fieldHelpers] = useField({
    name,
    validate: handleValidation,
  });
  const formContext = useFormikContext();

  const formikProps = (() => {
    const errorMessage =
      !inputProps.disabled &&
      (formikFieldMeta.touched ||
        alwaysShowError ||
        formContext.submitCount > 0)
        ? formikFieldMeta.error
        : undefined;

    const onChange = fieldHelpers.setValue;

    return {
      ...formikField,
      disabled: formContext.isSubmitting,
      errorMessage,
      onChange,
    };
  })();

  const props = {
    ...formikProps,
    ...inputProps,
    id: `${idBase}_${name}`,
    required,
  };

  const watchFieldValues = watchFieldNames.map((fieldName) =>
    getIn(formContext.values, fieldName)
  );

  const watchPropValues = watchProps.map(
    (prop) => props[prop as unknown as keyof typeof props]
  );

  const fieldSetError = useFieldSetError();
  const hasGroupError = fieldSetError.hasError;
  const isFieldInError = fieldSetError.fieldNames.includes(name);
  const showAsError = hasGroupError && isFieldInError;

  const response = useMemo(() => {
    return (
      <Component
        {...props}
        hasGroupError={showAsError}
        endAdornment={
          endAdornment ||
          (isValidating && (
            <InputAdornment position="end">
              <CircularProgress />
            </InputAdornment>
          ))
        }
      />
    );
    // limiting the requirements for the component to be re-rendered
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    name,
    formikProps.errorMessage,
    formikProps.value,
    props.disabled,
    showAsError,
    isValidating,
    ...watchFieldValues,
    ...watchPropValues,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return response;
}
