import { Box, BoxProps, Button, Stack, Typography } from "@mui/material";
import type { FormikConfig, FormikContextType, FormikValues } from "formik";
import { Form as FormikForm, Formik } from "formik";
import { ReactNode, SyntheticEvent, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";
import isEqual from "lodash.isequal";

import { HttpError } from "src/utilities/HttpError";

const FormLeaveGuard = dynamic(() => import("./FormLeaveGuard"));

export interface IFormProps<Values extends FormikValues = FormikValues>
  extends Omit<FormikConfig<Values>, "onSubmit"> {
  submitLabel?: string;
  resetLabel?: string;
  onChange?: (values: Values, formik: FormikContextType<Values>) => void;
  onSubmit?: FormikConfig<Values>["onSubmit"];

  warnOnUnsavedLeave?: boolean;
  // Usage of buttonPosition param should be avoided
  buttonPosition?: "center" | "flex-end";
  disableButtons?: boolean;
  children?:
    | ((formikContext: FormikContextType<Values>) => ReactNode)
    | ReactNode;
  disableFormValidationMessage?: boolean;
  formProps?: BoxProps;
  disableReset?: boolean;
  // the namespace for the error messages ie: "SOME_PAGE.errors"
  submitErrorMessages?: string;
  fullWidth?: boolean;
  isGreyed?: boolean;
}

const defaultError =
  "Sorry, we are facing an unexpected issue. Please try again later.";

export function Form<Values extends FormikValues = FormikValues>({
  children,
  initialValues = {} as Values,
  onSubmit = () => {},
  onChange = () => {},
  submitLabel = "Submit",
  onReset,
  resetLabel = "Reset",
  validate,
  warnOnUnsavedLeave = false,
  disableFormValidationMessage,
  buttonPosition = "center",
  disableButtons,
  formProps,
  disableReset,
  submitErrorMessages,
  enableReinitialize = false,
  validateOnMount,
  fullWidth,
  isGreyed,
}: IFormProps<Values>): JSX.Element {
  const [leaveOnReset, setLeaveOnReset] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const errorMessages = useTranslations(submitErrorMessages);
  const t = useTranslations("FORM");
  const handleSubmit: FormikConfig<Values>["onSubmit"] = async (
    values,
    helpers
  ) => {
    helpers.setStatus({
      formError: undefined,
    });

    try {
      await onSubmit(values, helpers);
      // We need this because Formik sets isSubmitting to false before navigation is done as we don't wait for router.push to resolve
      setIsSubmitted(true);
    } catch (error) {
      let formError = (error as Error)?.message;
      if (error instanceof HttpError && submitErrorMessages) {
        const errorMessage = errorMessages(error.code);
        if (!errorMessage.startsWith(submitErrorMessages)) {
          formError = errorMessages(error.code);
        }
      }
      if (!formError) {
        formError = defaultError;
      }

      helpers.setStatus({ formError });
    }
  };

  const handleReset: (formik: FormikContextType<Values>) => void = (formik) => {
    formik.handleReset();
    if (onReset) {
      onReset(formik.values, formik);
    }
    setLeaveOnReset(false);
  };

  const onResetClick: (formik: FormikContextType<Values>) => void = (
    formik
  ) => {
    if (formik.dirty && warnOnUnsavedLeave) {
      setLeaveOnReset(true);
    } else {
      handleReset(formik);
    }
  };
  const pastValuesRef = useRef<Values>();

  const buttonMaxWidthStyle = fullWidth ? "100%" : "15.625";

  return (
    <Formik<Values>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnMount={validateOnMount}
      enableReinitialize={enableReinitialize}
      onReset={(values, formikHelpers) => {
        if (onReset && disableButtons) {
          onReset(values, formikHelpers);
        }
      }}
    >
      {(formik) => {
        if (
          (formik.dirty || pastValuesRef.current) &&
          !isEqual(formik.values, pastValuesRef.current)
        ) {
          pastValuesRef.current = formik.values;
          onChange?.(formik.values, formik);
        }

        const formError =
          formik.status?.formError ||
          (formik.submitCount > 0 &&
            Object.values(formik.errors).length > 0 &&
            !disableFormValidationMessage &&
            t("errors.incorrectData"));
        return (
          <Box
            component={FormikForm}
            sx={{
              textAlign: "left",
            }}
            noValidate
            onReset={(event: SyntheticEvent) => {
              if (disableReset) {
                if (onReset) {
                  onReset(formik.values, formik);
                }

                return;
              }

              formik.handleReset(event);
            }}
            {...formProps}
          >
            <Stack spacing={{ xs: 3, md: 4 }} direction="column">
              {typeof children === "function" ? children(formik) : children}

              <div
                aria-live="assertive"
                aria-atomic="true"
                style={{ margin: formError ? undefined : 0 }}
              >
                {formError && (
                  <Typography
                    color="error"
                    align="center"
                    sx={{
                      fontSize: "0.875rem",
                    }}
                  >
                    {formError}
                  </Typography>
                )}
              </div>
              {!disableButtons && (
                <Stack
                  justifyContent={buttonPosition}
                  alignItems="center"
                  spacing={{ xs: 1, md: 2 }}
                  direction={{ xs: "column-reverse", md: "row" }}
                >
                  {onReset && (
                    <Button
                      style={{
                        maxWidth: buttonMaxWidthStyle,
                      }}
                      color="white"
                      type="reset"
                      disabled={formik.isSubmitting}
                      fullWidth
                      onClick={(event: SyntheticEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        onResetClick(formik);
                      }}
                    >
                      {resetLabel}
                    </Button>
                  )}

                  <Button
                    color="primary"
                    style={{
                      maxWidth: buttonMaxWidthStyle,
                    }}
                    type="submit"
                    disabled={formik.isSubmitting || isGreyed}
                  >
                    {submitLabel}
                  </Button>
                </Stack>
              )}
            </Stack>
            {warnOnUnsavedLeave && (
              <FormLeaveGuard
                shouldWarn={
                  !formik.isSubmitting && !isSubmitted && formik.dirty
                }
                handleLeaveOnResetConfirm={() => handleReset(formik)}
                handleLeaveOnResetCancel={() => setLeaveOnReset(false)}
                leaveOnReset={leaveOnReset}
              />
            )}
          </Box>
        );
      }}
    </Formik>
  );
}
