import { createContext, ReactNode, useContext } from "react";

interface IFieldSetErrorContext {
  hasError: boolean;
  errorMessage?: string;
  fieldNames: string[];
}

export const FieldSetErrorContext = createContext<IFieldSetErrorContext>({
  hasError: false,
  fieldNames: [],
});

interface IFieldSetErrorProviderProps {
  children: ReactNode;
  hasError?: boolean;
  errorMessage?: string;
  fieldNames: string[];
}

export function FieldSetErrorProvider({
  children,
  errorMessage,
  fieldNames,
  hasError,
}: IFieldSetErrorProviderProps): JSX.Element {
  return (
    <FieldSetErrorContext.Provider
      value={{
        hasError: hasError || errorMessage != null,
        errorMessage,
        fieldNames,
      }}
    >
      {children}
    </FieldSetErrorContext.Provider>
  );
}

export function useFieldSetError(): IFieldSetErrorContext {
  return useContext(FieldSetErrorContext);
}
