import type { ChangeEventHandler, ReactNode, ChangeEvent } from "react";
import { useCallback } from "react";
import type { OutlinedInputProps } from "@mui/material";
import { Input, OutlinedInput, TextFieldProps } from "@mui/material";

import { IInputLayoutProps, InputLayout } from "../InputLayout";

export interface IBasicInputProps<V = string>
  extends Omit<OutlinedInputProps, "onChange" | "label" | "componentsProps">,
    Omit<IInputLayoutProps, "helper" | "children"> {
  onChange: (value: V, event: ChangeEvent<HTMLInputElement>) => void;
  value: V;
  helper?: (
    input: Omit<
      IBasicInputProps,
      | "onChange"
      | "label"
      | "errorMessage"
      | "helperMessage"
      | "id"
      | "helper"
      | "labelAdornment"
      | "labelAdornmentFullWidth"
      | "hasGroupError"
      | "onBlur"
      | "visuallyHiddenLabel"
    >
  ) => ReactNode;
  hasGroupError?: boolean;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  variant?: TextFieldProps["variant"];
  disableUnderline?: boolean;
  visuallyHiddenLabel?: boolean;
  required?: boolean;
}

export function BasicInput({
  onChange,
  label,
  errorMessage,
  helperMessage,
  id,
  helper,
  labelAdornment,
  labelAdornmentFullWidth,
  hasGroupError,
  variant = "outlined",
  disableUnderline,
  visuallyHiddenLabel,
  ...props
}: IBasicInputProps): JSX.Element {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onChange(event.target.value, event);
    },
    [onChange]
  );

  const Component = variant === "outlined" ? OutlinedInput : Input;

  return (
    <InputLayout
      id={id}
      label={label as string}
      hasGroupError={hasGroupError}
      errorMessage={errorMessage}
      helperMessage={helperMessage}
      helper={helper?.(props)}
      labelAdornment={labelAdornment}
      labelAdornmentFullWidth={labelAdornmentFullWidth}
      visuallyHiddenLabel={visuallyHiddenLabel}
    >
      <Component
        {...(variant !== "outlined" && {
          disableUnderline,
        })}
        sx={{
          "& .MuiOutlinedInput-input": {
            ...(() => props.startAdornment && { paddingLeft: 0 })(),
            ...(() => props.endAdornment && { paddingright: 0 })(),
          },
          ...props.sx,
        }}
        {...props}
        inputProps={{
          sx: {
            "&::-ms-reveal": {
              display: "none",
            },
            ...props.inputProps?.sx,
          },
          ...props.inputProps,
        }}
        id={id}
        aria-describedby={props["aria-describedby"] ?? `${id}_description`}
        onChange={handleChange}
      />
    </InputLayout>
  );
}
